import { Button, Card, CircularProgress, HelpText, Input, Label } from '@hyperflake/react-ui-library';
import { formatNumeral, NumeralThousandGroupStyles } from 'cleave-zen';
import { ErrorMessage, Form, Formik } from 'formik';
import { CategoryOptions, ListingStatusOptions, ListingTypeOptions } from 'modules/listing/helpers/listing.constants';
import useListingAddForm from 'modules/listing/hooks/useListingAddForm';
import { Img, PageHeading, PageTemplate } from 'modules/shared/features/content';
import { FormikInput, FormikSelect, FormikSwitch, RequiredMark } from 'modules/shared/features/formik';
import FormikTextarea from 'modules/shared/features/formik/components/FormikTextarea/FormikTextarea';
import { Districts } from 'modules/shared/helpers/shared.constants';
import { DeleteIcon, UploadIcon } from 'modules/shared/icons';
import useLeanUserListData from 'modules/users/hooks/useLeanUserListData';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ListingAddForm = () => {
    const listingAddForm = useListingAddForm();
    const navigate = useNavigate();
    const handleSubmit = async (values: any) => {
        const modifyPrice = {
            ...values,
            price: typeof values.price === 'number' ? values.price : parseFloat(values.price.replace(/,/g, '')),
        };
        try {
            await listingAddForm.submit(modifyPrice);
            toast.success('Listing added successfully');
            navigate('/listings');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };

    const { users, isLoading } = useLeanUserListData();

    const userOption = useMemo(() => {
        if (!users) return [];
        return [
            { label: 'Select Lister', value: '' },
            ...users.map((val) => ({ label: `${val.name}-${val._id}`, value: val._id })),
        ];
    }, [users]);

    const districtOption = useMemo(() => {
        if (!Districts) return [];
        return [
            { label: 'Select District', value: '' },
            ...Districts.map((val: string) => ({ label: val, value: val })),
        ];
    }, []);

    return (
        <PageTemplate>
            <PageHeading>Add Listing</PageHeading>
            <Formik
                initialValues={listingAddForm.initialValues}
                enableReinitialize
                validationSchema={listingAddForm.schema}
                // validateOnChange={false}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <Card className="mt-4 ">
                            <Card.Header>
                                <h6>Basic Details</h6>
                            </Card.Header>
                            <Card.Body>
                                <div className="grid grid-cols-2 gap-4">
                                    {/* Title */}
                                    <div className="col-span-2">
                                        <Label>
                                            Title <RequiredMark />
                                        </Label>
                                        <FormikInput type="text" name="title" />
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="title" />
                                        </HelpText>
                                    </div>
                                    {/* overview */}
                                    <div className="col-span-2">
                                        <Label>
                                            Overview <RequiredMark />
                                        </Label>
                                        <FormikTextarea rows={2} name="overview" />
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="overview" />
                                        </HelpText>
                                    </div>

                                    {/* <div className="">
                                        <Label>
                                            Price <RequiredMark />
                                        </Label>
                                        <FormikInput type="number" name="price" />
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="price" />
                                        </HelpText>
                                    </div> */}
                                    <div className="col-span-1">
                                        <Label>
                                            Price <RequiredMark />
                                        </Label>

                                        <Input
                                            value={formatNumeral(values.price.toString(), {
                                                numeralThousandsGroupStyle: NumeralThousandGroupStyles.LAKH,
                                            })}
                                            onChange={(e) => {
                                                setFieldValue('price', e.target.value);
                                            }}
                                        />

                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="price" />
                                        </HelpText>
                                    </div>
                                    {/* status */}
                                    <div className="col-span-1">
                                        <Label>
                                            Status <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect
                                                name="status"
                                                options={ListingStatusOptions}
                                                // disabled={formik.values.pointedTo !== ''}
                                            />
                                        </div>
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="status" />
                                        </HelpText>
                                    </div>

                                    {/* category */}
                                    <div className="col-span-1">
                                        <Label>
                                            Category <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect
                                                name="category"
                                                options={CategoryOptions}
                                                // disabled={formik.values.pointedTo !== ''}
                                            />
                                        </div>
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="category" />
                                        </HelpText>
                                    </div>
                                    {/* type */}
                                    <div>
                                        <Label>
                                            Type <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect
                                                name="type"
                                                options={ListingTypeOptions}
                                                // disabled={formik.values.pointedTo !== ''}
                                            />
                                        </div>
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="type" />
                                        </HelpText>
                                    </div>
                                    <div className="col-span-2">
                                        <Label>
                                            Lister <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect name="lister" options={userOption} />
                                            {isLoading && <CircularProgress size={14} />}
                                        </div>
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="lister" />
                                        </HelpText>
                                    </div>

                                    {/* isFeatured */}
                                    <div className="col-span-2 mt-4">
                                        <div className="flex items-center gap-2">
                                            <Label htmlFor="isFeatured" className="font-medium text-grayscale-900 mb-0">
                                                Is Featured
                                            </Label>

                                            <FormikSwitch color="success" name="isFeatured" />
                                        </div>
                                        <div className="flex items-center gap-2 mt-4">
                                            <Label htmlFor="isVerified" className="font-medium text-grayscale-900 mb-0">
                                                Is Verified
                                            </Label>

                                            <FormikSwitch color="success" name="isVerified" />
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        {/* location */}
                        <Card className="mt-4 ">
                            <Card.Header>
                                <h6>Location</h6>
                            </Card.Header>
                            <Card.Body>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="col-span-2">
                                        <Label>
                                            Name <RequiredMark />
                                        </Label>
                                        <FormikInput type="text" name="location.name" />
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="location.name" />
                                        </HelpText>
                                    </div>

                                    <div className="col-span-2">
                                        <Label>Landmark</Label>
                                        <FormikInput type="text" name="location.landmark" />
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="location.landmark" />
                                        </HelpText>
                                    </div>
                                    <div className="col-span-2">
                                        <Label>
                                            District <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect
                                                name="location.district"
                                                options={districtOption}
                                                // disabled={formik.values.pointedTo !== ''}
                                            />
                                        </div>
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="location.district" />
                                        </HelpText>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="mt-4">
                            <Card.Header>
                                <h6>Images</h6>
                            </Card.Header>

                            <Card.Body className="grid grid-cols-4 gap-4 mb-4 empty:hidden">
                                {values.files.map((Image: any, index: number) => (
                                    <div key={index} className="col-span-1 relative rounded-md overflow-hidden">
                                        <Img
                                            src={URL.createObjectURL(values.files[index])}
                                            className="w-full h-full aspect-square object-cover object-center"
                                        />

                                        <button
                                            className="absolute right-2 top-2 w-6 h-6 grid place-items-center bg-card-color rounded-md text-grayscale-500"
                                            onClick={() => values.files.splice(index, 1)}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                ))}
                            </Card.Body>
                            {values.files.length < 9 && (
                                <div className="relative flex justify-center cursor-pointer bg-primary/5 py-6 rounded-lg border border-dashed">
                                    <Button variant={'shaded'} size={'xs'} className="cursor-pointer">
                                        <UploadIcon className="cursor-pointer" /> Upload Image
                                    </Button>
                                    <input
                                        type="file"
                                        className="absolute z-20 top-0 left-0 bottom-0 right-0 w-full h-full opacity-0 cursor-pointer"
                                        accept="image/png,image/jpg"
                                        onChange={(event) => {
                                            const files = event.target.files;

                                            if (files && files.length > 0) {
                                                setFieldValue(`files[${values.files.length}]`, files[0]);
                                                setFieldValue(`images[${values.files.length}]`, files[0]);
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </Card>
                        <Button type="submit" size={'lg'} className="mt-8 w-28">
                            {listingAddForm.isSubmitting ? <CircularProgress size={20} color={'inherit'} /> : 'Submit'}
                        </Button>
                    </Form>
                )}
            </Formik>
        </PageTemplate>
    );
};

export default ListingAddForm;
