import { Button, Label } from '@hyperflake/react-ui-library';
import { useAuth } from '@store/AuthContext';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { FormikInput } from 'modules/shared/features/formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Yup from 'yup';

const loginFormSchema = Yup.object({
    username: Yup.string().required('This field is required.'),
    password: Yup.string().required('This field is required.'),
});

interface ILogin {
    username: string;
    password: string;
}

interface LoginProps {}

const initialValues: ILogin = {
    username: '',
    password: '',
};
const Login = () => {
    const navigate = useNavigate();
    const { token, login } = useAuth();

    useEffect(() => {
        if (token) {
            navigate('/', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loginMutation = useMutation(async (payload: ILogin) => {
        // const { data } = await axios.post(`${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/auth/login`, payload);

        const { data } = await axios.post(`${import.meta.env.VITE_APP_AUTH_URL}`, payload);

        return data;
    });
    const handleSubmit = async (values: ILogin) => {
        if (!values.username || !values.password) {
            toast.error('Invalid Credentials.');
            return;
        }
        try {
            const res = await loginMutation.mutateAsync(values);
            const token = res.token;

            // when use will also be returned
            // const payload: Partial<IAgencyUser> = jwtDecode(token);
            // const currentUser = { _id: payload._id, name: payload.name, agency: payload.agency };
            // dispatch(authActions.login({ token: token, user: currentUser, rememberMe: true }));

            // dispatch(authActions.login({ token: token, rememberMe: true }));

            // localStorage.setItem('auth_token', token);
            login(token);
            navigate('/', { replace: true });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            toast.error(err.response.data.message);
        }
    };
    return (
        <div className="w-full min-h-screen  flex bg-[#9799d5]">
            <div className="card rounded-2xl px-9 py-8 w-full max-w-[580px] m-auto">
                <div className="flex flex-col  gap-4 text-black ">
                    <img className="w-14" src="/svgs/one-kanal-logo.svg" />
                    <h3>One Kanal Admin</h3>
                </div>

                <div className="mt-6 bg-white p-6 py-8 rounded-lg">
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={loginFormSchema}>
                        <Form>
                            <div>
                                <Label htmlFor="username">Email or Username</Label>
                                <FormikInput type="text" name="username" placeholder="Enter your email or username" />
                                {/* <FormHelperText variant={"error"}>
                  <ErrorMessage name="username" />
                </FormHelperText> */}
                            </div>

                            <div className="mt-4">
                                <Label htmlFor="password">Password</Label>
                                <FormikInput type="password" name="password" placeholder="Enter your password" />
                                {/* <FormHelperText variant={"error"}>
                  <ErrorMessage name="password" />
                </FormHelperText> */}
                            </div>

                            <Button
                                type="submit"
                                className="mt-4 w-full"
                                size={'xl'}
                                // disabled={loginMutation.isLoading}
                            >
                                Login
                                {/* {loginMutation.isLoading ? (
                  <CircularProgress className="text-white" size={24} />
                ) : (
                  "Login"
                )} */}
                            </Button>

                            {/* <div className="mt-12 text-sm">
                                <span className="text-gray-500 mr-2">Not on Farepod yet?</span>
                                <a className="text-blue-600 font-semibold underline cursor-pointer">Sign Up</a>
                            </div> */}
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default Login;
