// import { CircularProgress } from '@library/loaders/components';
import { CircularProgress } from '@hyperflake/react-ui-library';
import { FC, ReactNode } from 'react';

interface DisplayImageViewProps {
    children: ReactNode;
    showRemove: boolean;
    onRemove: () => void;
    isUploading?: boolean;
    uploadProgress?: number;
}

const DisplayImageView = (props: DisplayImageViewProps) => {
    const { children, showRemove, onRemove, isUploading, uploadProgress } = props;

    return (
        <div className="relative border border-separator-color rounded-full select-none">
            {children}

            {showRemove && (
                <div
                    className="absolute top-0 left-0 w-full h-full rounded-full flex items-center justify-center text-xs font-semibold text-white bg-black/40 cursor-pointer select-none transition-opacity duration-300 opacity-0 hover:opacity-100"
                    onClick={onRemove}
                >
                    Remove
                </div>
            )}

            {isUploading && (
                <div className="absolute top-0 left-0 w-full h-full rounded-full flex items-center justify-center bg-white/80 text-primary select-none">
                    <CircularProgress variant="indeterminate" size={88} />
                </div>
            )}
        </div>
    );
};

export default DisplayImageView;
