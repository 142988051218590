export const Districts = [
    'Srinagar',
    'Baramulla',
    'Anantnag',
    'Ganderbal',
    'Bandipora',
    'Budgam',
    'Kulgam',
    'Kupwara',
    'Pulwama',
    'Shopian',
];
