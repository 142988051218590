import { getAllUsersLean } from '@api/services/user.service';
import useDataQuery from 'modules/shared/hooks/use-data-query';

const useLeanUserListData = () => {
    const userListQuery = useDataQuery(['Users'], () => getAllUsersLean());

    return {
        users: userListQuery.data,

        isLoading: userListQuery.isLoading,
    };
};

export default useLeanUserListData;
