import { ILoginUser } from '@api/models/admin-user.model';
import { createContext, useContext } from 'react';

interface AuthContextType {
    isLoggedIn: boolean;
    token: string;
    user: ILoginUser;
    login: (token: string) => void;
    logout: () => void;
    setUser: (user: ILoginUser) => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) throw new Error('useAuth should be wrapper inside <AuthProvider /> ');

    return context;
};
