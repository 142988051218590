import { IListingImage } from '@api/models';
import { getListingById } from '@api/services/listing.service';
import { produce } from 'immer';
import useDataQuery from 'modules/shared/hooks/use-data-query';
import { useCallback } from 'react';

const useListingData = (listingId: string) => {
    const listingQuery = useDataQuery(['listingById', listingId], () => getListingById(listingId), {
        enabled: Boolean(listingId),
    });

    const setImages = useCallback(
        (images: IListingImage[]) => {
            listingQuery.setData((prev) => {
                return produce(prev, (draftState) => {
                    draftState.images = images;
                });
            });
        },
        [listingQuery]
    );

    return {
        isLoading: listingQuery.isLoading,
        listing: listingQuery.data,
        setData: listingQuery.setData,
        setImages,
    };
};

export default useListingData;
