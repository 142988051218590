import { IUser } from '@api/models';
import { Avatar, Label } from '@hyperflake/react-ui-library';
import { useMutation } from '@tanstack/react-query';
import http from 'httpConfig/http';
import DisplayImageView from 'modules/shared/components/DisplayImageView/DisplayImageView';

import { toast } from 'react-toastify';

interface ILogoForm {
    user: IUser;
    onDpUrlUpdate: (updatedUser: IUser) => void;
}

const UserUpdateDp = (props: ILogoForm) => {
    const { user, onDpUrlUpdate } = props;

    // const updateDpUpdateMutation = useMutation(async (payload: FormData) => {
    //     const { data } = await http.post(`${import.meta.env.VITE_APP_API_URL}/user/${user._id}/profile-image`, payload);

    //     return data as Pick<IUser, 'dp'>;
    // });

    const removeDpMutation = useMutation(async () => {
        await http.delete(`${import.meta.env.VITE_APP_API_URL}/user/${user._id}/profile-image`);
    });

    // const handleUserDpUpdate = async (file: File) => {
    //     try {
    //         const formData = new FormData();

    //         formData.append('file', file);

    //         const { dp } = await updateDpUpdateMutation.mutateAsync(formData);

    //         onDpUrlUpdate({ ...user, dpUrl: dp });

    //         toast.success('Dp updated successfully');
    //     } catch (err: any) {
    //         toast.error(err.data.message || 'Some error occured');

    //         console.log(err);
    //     }
    // };

    const handleUserDpRemove = async () => {
        try {
            await removeDpMutation.mutateAsync();
            toast.success('Dp removed successfully');
            onDpUrlUpdate({ ...user, dpUrl: null });
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured');

            console.log(err);
        }
    };

    return (
        <div>
            <Label>Display Image</Label>

            <div className="flex items-center gap-3">
                <DisplayImageView
                    showRemove={Boolean(user.dpUrl)}
                    onRemove={handleUserDpRemove}
                    // isUploading={updateUserDpMutation.isLoading || removeUserDpMutation.isLoading}
                >
                    <Avatar src={user.dpUrl || '/svgs/dummy-profile.svg'} alt={user.name} className="text-3xl" />
                </DisplayImageView>

                {/* <div className="flex flex-col items-center">
                    <UploadImageButton onFileChange={handleUserDpUpdate} />
                    <div className="text-xs font-medium text-grayscale-400 mt-2">Max size up to 10MB</div>
                </div> */}
            </div>
        </div>
    );
};

export default UserUpdateDp;
