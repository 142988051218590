import { LinearProgress } from '@hyperflake/react-ui-library';

const PageLoader = () => {
    return (
        <div className="flex flex-col justify-center items-center h-[80vh]">
            <div className="max-w-xs w-full">
                <LinearProgress />
            </div>
            <div className="text-sm text-grayscale-500 font-medium mt-4">Loading...</div>
        </div>
    );
};

export default PageLoader;
