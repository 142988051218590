import { UserStatusEnum, UserTypeEnum } from '@api/enums';
import { IUser } from '@api/models/user.model';
import { updateUser } from '@api/services/user.service';
import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import * as Yup from 'yup';

interface userEditProps {
    user: IUser;
}

const useUserEditForm = (props: userEditProps) => {
    const { user } = props;
    const updateUsertMutation = useMutation((payload: Partial<IUser>) => updateUser(user._id, payload));

    const schema = Yup.object({
        name: Yup.string().trim(),
        company: Yup.object().shape({
            name: Yup.string().test('validate-company-name', function (name: string) {
                const { type } = this.options.context;

                if (type === UserTypeEnum.BROKER && !name?.trim())
                    return this.createError({ message: 'This field is required' });

                return true;
            }),
            address: Yup.string().test('validate-company-address', function (address: string) {
                const { type } = this.options.context;

                if (type === UserTypeEnum.BROKER && !address?.trim())
                    return this.createError({ message: 'This field is required' });

                return true;
            }),
        }),
        phoneNumber: Yup.number(),
        dp: Yup.mixed().nullable(),
        emailHeaderLogo: Yup.mixed().nullable(),
        type: Yup.string().required('This field is required'),
        status: Yup.string().required('This field is required'),
    });

    const initialValues = useMemo(() => {
        if (!user)
            return {
                _id: '',
                name: '',
                company: {
                    name: '',
                    address: '',
                },
                phoneNumber: '',
                whatsappNumber: '',
                dp: '',
                dpUrl: '',
                type: UserTypeEnum.BROKER,
                status: UserStatusEnum.ENABLED,
                file: '',
            };

        return {
            _id: user._id,
            name: user.name,
            company: {
                name: user.company.name,
                address: user.company.address,
            },
            phoneNumber: user.phoneNumber,
            whatsappNumber: user.whatsappNumber,
            dp: user.dp,
            dpUrl: user.dpUrl,
            type: user.type,
            status: user.status,
            file: '',
        };
    }, [user]);

    const submit = async (payload: Partial<IUser>) => {
        const data = await updateUsertMutation.mutateAsync(payload);

        return data;
    };

    return {
        initialValues,
        schema,
        submit,
        isSubmitting: updateUsertMutation.isLoading,
    };
};

export default useUserEditForm;
