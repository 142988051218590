import { Sidebar } from '@hyperflake/react-ui-library';
import { useLocation, useNavigate } from 'react-router-dom';

const AppSidebar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const links = [
        {
            content: 'Dashboard',
            isActive: pathname.startsWith('/dashboard'),
            onClick: () => navigate('/dashboard'),
        },
        {
            content: 'Users',
            isActive: pathname.startsWith('/users'),
            onClick: () => navigate('/users'),
        },
        {
            content: 'Listing',
            isActive: pathname.startsWith('/listings'),
            onClick: () => navigate('/listings'),
        },
    ];

    return (
        <Sidebar className="pt-0">
            <Sidebar.Content>
                <img className="h-8" src="/svgs/one-kanal-logo.svg" alt="" onClick={() => navigate('/')} />
            </Sidebar.Content>

            <Sidebar.Nav>
                {links.map((row, i) => (
                    <Sidebar.NavItem key={i} isActive={row.isActive} onClick={row.onClick}>
                        {row.content}
                    </Sidebar.NavItem>
                ))}
            </Sidebar.Nav>
        </Sidebar>
    );
};

export default AppSidebar;
