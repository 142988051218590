import { UserTypeEnum } from '@api/enums';
import { IUser } from '@api/models';
import { Button, Card, CircularProgress, HelpText, Label } from '@hyperflake/react-ui-library';
import { ErrorMessage, Form, Formik } from 'formik';
import BackButton from 'modules/shared/components/BackButton/BackButton';
import { PageHeading, PageLoader, PageTemplate } from 'modules/shared/features/content';
import { FormikInput, FormikSelect, RequiredMark } from 'modules/shared/features/formik';
import { statusOption, userTypeOption } from 'modules/users/helpers/user.util';
import useUserData from 'modules/users/hooks/useUserData';
import useUserEditForm from 'modules/users/hooks/useUserEditForm';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserUpdateDp from '../UserUpdateDp/UserUpdateDp';

const UserEditForm = () => {
    const { userId } = useParams();

    const navigate = useNavigate();

    const userData = useUserData(userId);

    const userEditFormData = useUserEditForm({ user: userData.data });

    const handleSubmit = async (values: Partial<IUser>) => {
        try {
            await userEditFormData.submit(values);

            toast.success('User Updated Successfully');

            navigate('/users');
        } catch (err: any) {
            console.log(err);

            toast.error(err?.data?.message || 'Oops something went wrong.');
        }
    };

    const handleUserDpUpdate = (user: IUser) => {
        userData.setData(user);
    };

    if (userData.isLoading) return <PageLoader />;

    return (
        <PageTemplate>
            <BackButton className="mb-2" />

            <PageHeading>Edit User</PageHeading>

            <Card className="mt-4">
                <Card.Header>
                    <h6>Profile Picture</h6>
                </Card.Header>
                <Card.Body>
                    <UserUpdateDp user={userData.data} onDpUrlUpdate={handleUserDpUpdate} />
                </Card.Body>
            </Card>

            <Formik
                initialValues={userEditFormData.initialValues}
                validationSchema={userEditFormData.schema}
                onSubmit={handleSubmit}
            >
                {({ values }) => (
                    <Form>
                        <Card className="mt-4">
                            <Card.Header>
                                <h6>Basic Details</h6>
                            </Card.Header>

                            <Card.Body>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="col-span-2">
                                        <Label>Name</Label>
                                        <FormikInput type="text" name="name" />
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="name" />
                                        </HelpText>
                                    </div>

                                    <div className="col-span-2">
                                        <Label>
                                            Phone Number <RequiredMark />
                                        </Label>
                                        <FormikInput type="number" name="phoneNumber" />
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="phoneNumber" />
                                        </HelpText>
                                    </div>

                                    <div className="col-span-2">
                                        <Label>
                                            Whatsapp Number <RequiredMark />
                                        </Label>
                                        <FormikInput type="number" name="whatsappNumber" />
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="whatsappNumber" />
                                        </HelpText>
                                    </div>

                                    <div className="col-span-1">
                                        <Label>
                                            Type <RequiredMark />
                                        </Label>
                                        <FormikSelect name="type" options={userTypeOption} />
                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="type" />
                                        </HelpText>
                                    </div>

                                    <div className="col-span-1">
                                        <Label>
                                            Status <RequiredMark />
                                        </Label>

                                        <FormikSelect name="status" options={statusOption} />

                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="role" />
                                        </HelpText>
                                    </div>

                                    {values.type === UserTypeEnum.BROKER && (
                                        <>
                                            <h5 className="col-span-2 mt-4">Company details</h5>

                                            <div className="">
                                                <Label>Company Name</Label>
                                                <FormikInput type="text" name="company.name" />
                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="company.name" />
                                                </HelpText>
                                            </div>
                                            <div className="">
                                                <Label>Company Address</Label>
                                                <FormikInput type="text" name="company.address" />
                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="company.address" />
                                                </HelpText>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <Button type="submit" size={'lg'} className="mt-8 w-28">
                                    {userEditFormData.isSubmitting ? (
                                        <CircularProgress size={20} color={'inherit'} />
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                            </Card.Body>
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageTemplate>
    );
};

export default UserEditForm;
