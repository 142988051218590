import { IListing, IListingImage } from '@api/models';
import { Button } from '@hyperflake/react-ui-library';
import { Popover } from '@hyperflake/react-ui-library/popover/index.js';
import { useMutation } from '@tanstack/react-query';
import http from 'httpConfig/http';
import { Img } from 'modules/shared/features/content';
import { DeleteIcon, UploadIcon } from 'modules/shared/icons';
import { ChangeEvent, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ListingImagesFormProps {
    images: IListingImage[];
    setImages: (images: IListingImage[]) => void;
}
const ListingImagesForm = (props: ListingImagesFormProps) => {
    const { images, setImages } = props;
    const [confirmPopOverId, setConfirmPopOverId] = useState(null);

    const { listingId } = useParams();

    const uploadListingImagesMutation = useMutation(async (payload: FormData) => {
        const { data } = await http.post<IListing>(
            `${import.meta.env.VITE_APP_API_URL}/listing/${listingId}/images`,
            payload
        );

        return data.images;
    });

    const removeListingImageMutation = useMutation(async (imageId: string) => {
        await http.delete(`${import.meta.env.VITE_APP_API_URL}/listing/${listingId}/images/${imageId}`);
    });

    const handleListingImageRemove = async (imageId: string) => {
        if (images.length === 1) {
            toast.error('Listing should at least contain one image.');
            return;
        }
        try {
            const index = images.findIndex((val) => val._id === imageId);

            if (index === -1) {
                return;
            }

            await removeListingImageMutation.mutateAsync(imageId);

            setImages(images.filter((val) => val._id !== imageId));

            toast.success('Image deleted successfully.');
            setConfirmPopOverId(null);
        } catch (err: any) {
            console.log(err);

            toast.error(err?.data?.message || 'Some error occured, please try again.');
        }
    };

    const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            const formData = new FormData();

            formData.append('files', files[0]);

            try {
                const images = await uploadListingImagesMutation.mutateAsync(formData);

                setImages(images);

                toast.success('Image Uploaded Successfully.');
            } catch (err: any) {
                console.log(err);

                toast.error(err?.data?.message || 'Some error occurred, please try again.');
            }
        }
    };

    return (
        <>
            <div className="flex flex-col h-full">
                <div className="grid grid-cols-3 gap-4 mb-4 empty:hidden">
                    {images.map((image) => (
                        <div key={image._id} className="col-span-1 relative rounded-md overflow-hidden">
                            <Zoom>
                                <Img
                                    src={image.url}
                                    className="w-full h-full aspect-square object-cover object-center"
                                />
                            </Zoom>
                            <Popover
                                className="absolute right-2 top-2"
                                placement="bottom-start"
                                contentClassName="px-6 py-5 rounded-2xl shadow-lg"
                                open={confirmPopOverId === image._id}
                                setOpen={() => setConfirmPopOverId(image._id)}
                                triggers="click"
                                content={
                                    <div>
                                        <p className="text-sm text-grayscale-500">Confirm Delete?</p>

                                        <div className="flex gap-3 mt-4">
                                            <Button
                                                size="xs"
                                                color="danger"
                                                onClick={() => handleListingImageRemove(image._id)}
                                            >
                                                Delete
                                            </Button>
                                            <Button
                                                onClick={() => setConfirmPopOverId(null)}
                                                size="xs"
                                                variant="shaded"
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                }
                            >
                                <button
                                    className="size-6 grid place-items-center bg-card-color rounded-md text-grayscale-500"
                                    onClick={() => setConfirmPopOverId(image._id)}
                                >
                                    <DeleteIcon />
                                </button>
                            </Popover>

                            {/* <Dropdown placement="bottom-end">
                                <DropdownTrigger className="absolute right-2 top-2 size-6 grid place-items-center bg-card-color rounded-md text-grayscale-500">
                                    <div
                                        className={`relative inline-flex items-center text-xs px-2 py-1 rounded-full font-semibold bg-card-color text-grayscale-500 cursor-pointer`}
                                        // onClick={handleMacOnClick}
                                    >
                                        <DeleteIcon />
                                    </div>
                                </DropdownTrigger>

                                <DropdownList className="min-w-[100px]">
                                    <DropdownItem>
                                        <div className="">
                                            <p>Are you sure ?</p>
                                            <div className="flex justify-around mt-4 gap-4">
                                                <Button
                                                    color="primary"
                                                    size="xs"
                                                    onClick={() => handleListingImageRemove(image._id)}
                                                >
                                                    Yes
                                                </Button>
                                                <Button color="primary" size="xs">
                                                    No
                                                </Button>
                                            </div>
                                        </div>
                                    </DropdownItem>
                                </DropdownList>
                            </Dropdown> */}
                        </div>
                    ))}
                </div>

                {images.length < 9 && (
                    <div className="relative flex justify-center cursor-pointer bg-primary/10 py-10 rounded-lg">
                        <Button variant={'shaded'} size={'sm'} className="cursor-pointer ">
                            <UploadIcon className="cursor-pointer" /> Upload Image
                        </Button>

                        <input
                            type="file"
                            className="absolute z-20 top-0 left-0 bottom-0 right-0 w-full h-full opacity-0 cursor-pointer"
                            accept="image/png,image/jpg"
                            onChange={handleImageUpload}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ListingImagesForm;
