import { CategoryEnum } from '@api/enums/category.enum';
import { ListingStatusEnum } from '@api/enums/listing-status.enum';
import { ListingTypeEnum } from '@api/enums/listing-type.enum';
import capitalize from 'lodash.capitalize';

const Districts = [
    'Srinagar',
    'Baramulla',
    'Anantnag',
    'Ganderbal',
    'Bandipora',
    'Budgam',
    'Kulgam',
    'Kupwara',
    'Pulwama',
    'Shopian',
];

export const CategoryOptions = Object.values(CategoryEnum).map((role) => ({
    label: capitalize(role),
    value: role,
}));

export const ListingTypeOptions = Object.values(ListingTypeEnum).map((role) => ({
    label: capitalize(role),
    value: role,
}));

export const ListingStatusOptions = Object.values(ListingStatusEnum).map((role) => ({
    label: capitalize(role),
    value: role,
}));

export const DistrictsOptions = Districts.map((district) => ({
    label: district,
    value: district,
}));

export const ListingFilterValues = Object.freeze({
    ALL: 'ALL',
    PENDING: ListingStatusEnum.PENDING,
    ACTIVE: ListingStatusEnum.ACTIVE,
    INACTIVE: ListingStatusEnum.INACTIVE,
});
