import { getAllUsers } from '@api/services/user.service';
import { useMutation } from '@tanstack/react-query';
import useDataQuery from 'modules/shared/hooks/use-data-query';

const useUserListData = (page?: number) => {
    // const userListQuery = useDataQuery(['Users', page], () => getAllUsers(page), {
    //     enabled: Boolean(page),
    // });

    const userListQuery = useDataQuery(['Users'], () => getAllUsers());

    return {
        users: userListQuery.data,
        // users: userListQuery?.data?.users,
        // pageCount: userListQuery?.data?.page,
        isLoading: userListQuery.isLoading,
    };
};

export default useUserListData;
