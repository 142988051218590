import { Field, FieldAttributes, FieldProps } from 'formik';
import { Textarea, TextareaProps } from '@hyperflake/react-ui-library/forms';
import { ForwardedRef, forwardRef, useMemo } from 'react';

interface FormikTextareaProps extends TextareaProps {
    fieldProps?: FieldAttributes<TextareaProps>;
}

const FormikTextarea = forwardRef((props: FormikTextareaProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const { name, id, fieldProps, invalid, ...rest } = props;

    const idOrName = useMemo(() => {
        return id || name;
    }, [id, name]);

    return (
        <Field name={name} {...fieldProps}>
            {({ field, meta }: FieldProps) => (
                <Textarea
                    {...rest}
                    {...field}
                    ref={ref}
                    id={idOrName}
                    name={name}
                    invalid={(meta.touched && Boolean(meta.error)) || invalid}
                />
            )}
        </Field>
    );
});

export default FormikTextarea;
