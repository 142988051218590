import '@hyperflake/react-ui-library/style.css';
import 'react-medium-image-zoom/dist/styles.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.scss';
import AuthProvider from 'modules/shared/hocs/AuthProvider/AuthProvider.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <AuthProvider>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </AuthProvider>
);
