import { Avatar, Dropdown, DropdownItem, DropdownList, DropdownTrigger, Navbar } from '@hyperflake/react-ui-library';
import { useAuth } from '@store/AuthContext';
import { useNavigate } from 'react-router-dom';

const AppNavbar = () => {
    const { logout } = useAuth();

    const navigate = useNavigate();

    return (
        <Navbar className="px-4 z-20">
            <div onClick={() => navigate('/')}>
                <img className="h-8" src="/svgs/one-kanal-logo.svg" alt="" />
            </div>
            <div className="ml-auto flex items-center gap-5">
                <Dropdown>
                    <DropdownTrigger className="flex items-center gap-3">
                        <Avatar src={''} alt={'Admin'} />
                    </DropdownTrigger>
                    <DropdownList className="z-[82]">
                        <DropdownItem onClick={() => {}}>Account</DropdownItem>
                        <DropdownItem onClick={() => logout()}>
                            <span className="text-danger">Log out</span>
                        </DropdownItem>
                    </DropdownList>
                </Dropdown>
            </div>
        </Navbar>
    );
};

export default AppNavbar;
