import { UserTypeEnum } from '@api/enums';
import { IUser } from '@api/models';
import { Button, Card, CircularProgress, HelpText, Label } from '@hyperflake/react-ui-library';
import { ErrorMessage, Form, Formik } from 'formik';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { FormikInput, FormikSelect, RequiredMark } from 'modules/shared/features/formik';
import { statusOption, userTypeOption } from 'modules/users/helpers/user.util';
import useUserAddForm from 'modules/users/hooks/useUserAddForm';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const UserAddForm = () => {
    const addUserForm = useUserAddForm();
    const navigate = useNavigate();

    const handleSubmit = async (values: IUser) => {
        try {
            await addUserForm.submit(values);
            toast.success('User Added Successfully');
            navigate('/users');
        } catch (e: any) {
            console.log(e);
            toast.error(e.data.message);
        }
    };
    return (
        <>
            <PageTemplate>
                <PageHeading>Add User</PageHeading>
                <Formik
                    initialValues={addUserForm.initialValues}
                    //enableReinitialize
                    validationSchema={addUserForm.schema}
                    validateOnChange={false}
                    onSubmit={handleSubmit}
                >
                    {({ values, isValidating }) => (
                        <Form>
                            <Card className="mt-4 ">
                                <Card.Header>
                                    <h6>Basic Details</h6>
                                </Card.Header>

                                <Card.Body>
                                    <div className="grid grid-cols-2 gap-4">
                                        {/* name */}
                                        <div className="col-span-2">
                                            <Label>Name</Label>
                                            <FormikInput type="text" name="name" />
                                            <HelpText variant={'error'}>
                                                <ErrorMessage name="name" />
                                            </HelpText>
                                        </div>

                                        {/* phoneNumber */}
                                        <div className="col-span-2">
                                            <Label>
                                                Phone Number <RequiredMark />
                                            </Label>
                                            <FormikInput type="number" name="phoneNumber" />
                                            <HelpText variant={'error'}>
                                                <ErrorMessage name="phoneNumber" />
                                            </HelpText>
                                        </div>
                                        {/* whatsapp Number */}
                                        <div className="col-span-2">
                                            <Label>
                                                Whatsapp Number <RequiredMark />
                                            </Label>
                                            <FormikInput type="number" name="whatsappNumber" />
                                            <HelpText variant={'error'}>
                                                <ErrorMessage name="whatsappNumber" />
                                            </HelpText>
                                        </div>
                                        {/* Types */}
                                        <div>
                                            <Label>
                                                Type <RequiredMark />
                                            </Label>
                                            <div className="flex gap-2">
                                                <FormikSelect
                                                    name="type"
                                                    options={userTypeOption}
                                                    // disabled={formik.values.pointedTo !== ''}
                                                />
                                            </div>
                                            <HelpText variant={'error'}>
                                                <ErrorMessage name="type" />
                                            </HelpText>
                                        </div>
                                        {/* status */}
                                        <div>
                                            <Label>
                                                Status <RequiredMark />
                                            </Label>
                                            <div className="flex gap-2">
                                                <FormikSelect
                                                    name="status"
                                                    options={statusOption}
                                                    // disabled={formik.values.pointedTo !== ''}
                                                />
                                            </div>
                                            <HelpText variant={'error'}>
                                                <ErrorMessage name="status" />
                                            </HelpText>
                                        </div>

                                        {values.type === UserTypeEnum.BROKER && (
                                            <>
                                                <h5 className="col-span-2 mt-4">Company details</h5>

                                                <div className="">
                                                    <Label>Company Name</Label>
                                                    <FormikInput type="text" name="company.name" />
                                                    <HelpText variant={'error'}>
                                                        <ErrorMessage name="company.name" />
                                                    </HelpText>
                                                </div>
                                                <div className="">
                                                    <Label>Company Address</Label>
                                                    <FormikInput type="text" name="company.address" />
                                                    <HelpText variant={'error'}>
                                                        <ErrorMessage name="company.address" />
                                                    </HelpText>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <Button
                                        //   disabled={addUserForm.isSubmitting || isValidating}
                                        type="submit"
                                        size={'lg'}
                                        className="mt-8 w-28"
                                    >
                                        {addUserForm.isSubmitting ? (
                                            <CircularProgress size={20} color={'inherit'} />
                                        ) : isValidating ? (
                                            'Validating'
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Form>
                    )}
                </Formik>
            </PageTemplate>
            {/* // <UserForm
        //   title="Add User"
        //   initialValues={userFormData.initialValues}
        //   validationSchemes={userFormData.schema}
        //   onSubmit={handleSubmit}
        // /> */}
        </>
    );
};

export default UserAddForm;
