import { getAllListing } from '@api/services/listing.service';
import useDataQuery from 'modules/shared/hooks/use-data-query';
import { useCallback, useMemo, useState } from 'react';
import { ListingFilterValues } from '../helpers/listing.constants';
import { ListingStatusEnum } from '@api/enums/listing-status.enum';

const useListingListData = () => {
    const listingListQuery = useDataQuery(['listing'], () => getAllListing());

    const [filterValue, setFilterValue] = useState('ALL');

    const changeFilterValue = useCallback(
        (val: string | number) => {
            setFilterValue(val as string);
        },
        [setFilterValue]
    );

    const allListings = useMemo(() => {
        if (listingListQuery.isLoading) return [];

        const _listing = listingListQuery.data;

        const filterProjects =
            filterValue === ListingFilterValues.ALL ? _listing : _listing.filter((list) => list.status === filterValue);

        return filterProjects;
    }, [listingListQuery.isLoading, listingListQuery.data, filterValue]);

    const filterOptions = useMemo(() => {
        if (listingListQuery.isLoading) return [];

        const users = listingListQuery.data;

        const _filterOptions = [
            {
                label: `All (${users.length})`,
                value: ListingFilterValues.ALL,
            },
            {
                label: `Pending (${users.filter((row) => row.status === ListingStatusEnum.PENDING).length})`,
                value: ListingFilterValues.PENDING,
            },
            {
                label: `Active (${users.filter((row) => row.status === ListingStatusEnum.ACTIVE).length})`,
                value: ListingFilterValues.ACTIVE,
            },
            {
                label: `InActive (${users.filter((row) => row.status === ListingStatusEnum.INACTIVE).length})`,
                value: ListingFilterValues.INACTIVE,
            },
        ];

        return _filterOptions;
    }, [listingListQuery.isLoading, listingListQuery.data]);

    return {
        isLoading: listingListQuery.isLoading,
        listings: allListings,
        filterOptions,
        filterValue,
        changeFilterValue,
    };
};

export default useListingListData;
