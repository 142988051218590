import { ListingTypeEnum } from '@api/enums/listing-type.enum';
import { IUser } from '@api/models';
import { Button, Chip, Table } from '@hyperflake/react-ui-library';
import { getInitials } from '@hyperflake/react-ui-library/avatar/helpers/avatar.utils.js';
import useListingListData from 'modules/listing/hooks/useListingListData';
import SelectDropdown from 'modules/shared/components/SelectDropDown/SelectDropDown';
import { Img, PageHeading, PageTemplate } from 'modules/shared/features/content';
import { formatPrice } from 'modules/shared/helpers/shared.utils';
import { ChevronDownIcon } from 'modules/shared/icons';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

const ListingStatusColorMap = Object.freeze({
    ACTIVE: 'success',
    INACTIVE: 'secondary',
    PENDING: 'warning',
});

const ListingList = () => {
    const navigate = useNavigate();

    const { isLoading, listings, filterOptions, filterValue, changeFilterValue } = useListingListData();

    return (
        <PageTemplate>
            <div className="flex justify-between items-center">
                <PageHeading>Listing</PageHeading>

                <Link to="/listings/add">
                    <Button>Add Listing</Button>
                </Link>
            </div>

            <div className="flex justify-between items-center mt-4">
                <div className="flex items-center gap-2 ml-auto">
                    <SelectDropdown
                        className="btn-dropdown btn-secondary btn-secondary-hover "
                        value={filterValue}
                        onChange={changeFilterValue}
                        options={filterOptions}
                        render={(label) => (
                            <span className="flex items-center gap-2 text-grayscale-500 font-medium">
                                Status:<span className="text-grayscale-900 font-semibold">{label}</span>{' '}
                                <span>
                                    <ChevronDownIcon className="text-grayscale-400" strokeWidth="2" />
                                </span>
                            </span>
                        )}
                    />
                </div>
            </div>

            <Table
                className="mt-4"
                borderStyle="divider"
                isLoading={isLoading}
                data={listings}
                hover
                columns={[
                    {
                        header: 'Property',
                    },
                    {
                        header: 'Price',
                    },
                    {
                        header: 'District',
                    },
                    {
                        header: 'Type',
                    },
                    {
                        header: 'Status',
                    },
                    {
                        header: 'Posted By',
                    },
                    {
                        header: 'Posted On',
                    },
                ]}
                renderRow={(row) => (
                    <Table.Row key={row._id} onClick={() => navigate(`/listings/${row._id}/edit-listing`)}>
                        <Table.Cell>
                            <div className="flex items-center gap-3">
                                <div>
                                    <Img
                                        src={row.coverImageUrl}
                                        alt={getInitials(row.title)}
                                        className="h-12 aspect-image object-contain rounded-md bg-grayscale-200"
                                    />
                                </div>

                                <div className="max-w-xs">
                                    <div className="font-semibold truncate">{row.title}</div>
                                    <p className="text-sm text-gray-500 mt-[2px] truncate">
                                        {row.location.name} {row.location.landmark}
                                    </p>
                                </div>
                            </div>
                        </Table.Cell>
                        <Table.Cell>{formatPrice(row.price)}</Table.Cell>
                        <Table.Cell>{row.location.district}</Table.Cell>
                        <Table.Cell className={row.type === ListingTypeEnum.RENT ? 'text-primary' : 'text-success'}>
                            {row.type}
                        </Table.Cell>
                        <Table.Cell>
                            {row.isDeleted ? (
                                <Chip size="sm" label="DELETED" color="danger" />
                            ) : (
                                <Chip size="sm" label={row.status} color={ListingStatusColorMap[row.status]} />
                            )}
                        </Table.Cell>
                        <Table.Cell>
                            <div>
                                <div> {(row.lister as IUser).name || <i>-</i>}</div>
                                <div className="text-grayscale-500 mt-[2px]"> {(row.lister as IUser).phoneNumber}</div>
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>
                                {moment(row.createdAt).format('DD MMM, YYYY')}
                                <div className="text-grayscale-500 mt-[2px]">
                                    {' '}
                                    {moment(row.createdAt).format('hh:mm A')}
                                </div>
                            </div>
                        </Table.Cell>
                    </Table.Row>
                )}
            />
        </PageTemplate>
    );
};
export default ListingList;
