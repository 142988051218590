import { IListing } from '@api/models/listing.model';
import { updateListing } from '@api/services/listing.service';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';

const useListingEditForm = (listing: IListing) => {
    const updateListingMutation = useMutation((payload: Partial<IListing>) => updateListing(listing._id, payload));

    const initialValues = {
        title: listing?.title,
        overview: listing?.overview,
        location: {
            name: listing?.location.name,
            district: listing?.location.district,
            landmark: listing?.location.landmark,
            latitude: listing?.location.latitude,
            longitude: listing?.location.longitude,
        },
        price: listing?.price,
        category: listing?.category,
        type: listing?.type,
        lister: listing?.lister,
        status: listing?.status,
        isVerified: listing?.isVerified,
        isFeatured: listing?.isFeatured,
    };

    const schema = Yup.object({
        title: Yup.string().trim().required('Title is required'),
        overview: Yup.string().trim(),
        location: Yup.object({
            name: Yup.string().trim().required('Location name is required'),
            district: Yup.string().trim().required('District is required'),
            landmark: Yup.string().trim().nullable(),
            latitude: Yup.string().trim().nullable(),
            longitude: Yup.string().trim().nullable(),
        }).required('Location is required'),
        // price: Yup.number().required('Price is required').min(0, 'Price must be a positive number'),
        price: Yup.number()
            .transform((value, originalValue) => {
                console.log('originalValue', originalValue);
                // Check if originalValue is empty
                if (!originalValue) return undefined; // Return undefined if empty
                // Remove commas and convert to a number
                const cleanedValue =
                    typeof originalValue === 'number' ? originalValue : originalValue.replace(/,/g, '');
                const numberValue = parseFloat(cleanedValue); // Convert to number
                return isNaN(numberValue) ? undefined : numberValue; // Return undefined if NaN
            })
            .required('Price is required')
            .min(0, 'Price must be a positive number'),
        category: Yup.string().required('Category is required'), // Assuming it's an enum
        type: Yup.string().required('Property type is required'), // Assuming it's an enum
        status: Yup.string().required('Listing status is required'), // Assuming it's an enum
        isVerified: Yup.boolean().required('Verification status is required'),
        isFeatured: Yup.boolean().required('Featured status is required'),
    });

    const submit = async (payload: Partial<IListing>) => {
        const data = await updateListingMutation.mutateAsync(payload);

        return data;
    };

    return {
        initialValues,
        schema,
        submit,
        isSubmitting: updateListingMutation.isLoading,
    };
};

export default useListingEditForm;
