import { IListing } from '@api/models/listing.model';
import http from 'httpConfig/http';

export const getAllListing = async () => {
    const { data } = await http.get(`${import.meta.env.VITE_APP_API_URL}/listing`);

    const listing = data.data;

    return listing as IListing[];
};

export const getListingById = async (listingId: string) => {
    const { data } = await http.get(`${import.meta.env.VITE_APP_API_URL}/listing/${listingId}`);

    return data as IListing;
};

export const createListing = async (payload: any) => {
    const { data } = await http.post(`${import.meta.env.VITE_APP_API_URL}/listing`, payload);
    return data as IListing;
};

export const updateListing = async (userId: string, payload: Partial<IListing>) => {
    const { data } = await http.patch(`${import.meta.env.VITE_APP_API_URL}/listing/${userId}`, payload);

    return data as IListing;
};

export const uploadListingImages = async (listingId: string, payload: any) => {
    const { data } = await http.post(`${import.meta.env.VITE_APP_API_URL}/listing/${listingId}/images`, payload);

    return data;
};
