export const formatPrice = (price: number): string => {
    const formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
    });

    return formatter.format(price);
};

export const getInitials = (name: string) => {
    return name && name.length > 0 ? name.substring(0, 1).toUpperCase() : '';
};
