import { UserTypeEnum } from '@api/enums';
import { getAllUsers } from '@api/services/user.service';
import { Avatar, Button, Chip, Table } from '@hyperflake/react-ui-library';
import { useQuery } from '@tanstack/react-query';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { ArrowLeftIcon, ArrowRightIcon } from 'modules/shared/icons';
import useUserListData from 'modules/users/hooks/useUserListData';
import moment from 'moment';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const UserStatusColorMap = Object.freeze({
    ENABLED: 'success',
    DISABLED: 'secondary',
    DELETED: 'danger',
});

const UsersList = () => {
    const navigate = useNavigate();
    // const [page, setPage] = useState(1);

    // const { users, pageCount, isLoading } = useUserListData(page);
    const { users, isLoading } = useUserListData();
    // const { users, pageCount, isLoading } = useUserListData();

    // const handlePreviousPage = () => {
    //     setPage((prevPage) => Math.max(prevPage - 1, 1));
    // };

    // const handleNextPage = () => {
    //     setPage((prevPage) => Math.min(prevPage + 1, pageCount));
    // };

    return (
        <PageTemplate>
            <div className="flex justify-between items-center">
                <PageHeading>Users</PageHeading>

                <Link to="/users/add">
                    <Button>Add User</Button>
                </Link>
            </div>

            {/* <div className="flex justify-end mt-2 items-center">
                Pages:
                <div className="flex items-center gap-2">
                    <button
                        className="flex items-center justify-center text-primary font-medium size-8 rounded hover:bg-primary/10"
                        onClick={() => handlePreviousPage()}
                        disabled={page === 1}
                    >
                        <ArrowLeftIcon />
                    </button>

                    {page}

                    <button
                        className="flex items-center justify-center text-primary font-medium size-8 rounded hover:bg-primary/10"
                        onClick={() => handleNextPage()}
                        disabled={page === pageCount}
                    >
                        <ArrowRightIcon />
                    </button>
                </div>
            </div> */}

            <Table
                className="mt-4"
                borderStyle="divider"
                hover
                isLoading={isLoading}
                data={users}
                columns={[
                    {
                        header: 'Name',
                    },
                    {
                        header: 'Phone Number',
                    },
                    {
                        header: 'Type',
                    },
                    {
                        header: 'Status',
                    },
                    {
                        header: 'Created At',
                    },
                ]}
                renderRow={(row) => (
                    <Table.Row key={row._id} onClick={() => navigate(`/users/${row._id}/edit`)}>
                        <Table.Cell>
                            {
                                <>
                                    <div className="flex items-center gap-3">
                                        <div className="flex items-center gap-2">
                                            <Avatar src={row.dpUrl || '/svgs/dummy-profile.svg'} alt={row.name} />
                                        </div>
                                        <div className="max-w-xs">
                                            <div className="font-semibold truncate">{row.name || <i>NA</i>}</div>
                                            <p className="text-sm text-gray-500 mt-[2px] truncate">{row._id}</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </Table.Cell>
                        <Table.Cell>{row.phoneNumber}</Table.Cell>
                        <Table.Cell className={row.type === UserTypeEnum.BROKER ? 'text-success' : 'text-primary'}>
                            {row.type}
                        </Table.Cell>
                        <Table.Cell>
                            <Chip size="sm" label={row.status} color={UserStatusColorMap[row.status]} />
                        </Table.Cell>
                        <Table.Cell>
                            <div>
                                {moment(row.createdAt).format('DD MMM, YYYY')}
                                <div className="text-grayscale-500 mt-[2px]">
                                    {' '}
                                    {moment(row.createdAt).format('hh:mm A')}
                                </div>
                            </div>
                        </Table.Cell>
                    </Table.Row>
                )}
            />
        </PageTemplate>
    );
};

export default UsersList;
