import Login from 'modules/auth/Login';
import { ListingAddForm, ListingEditForm, ListingList } from 'modules/listing';
import AuthGuard from 'modules/shared/hocs/AuthGuard/AuthGuard';
import ErrorBoundary from 'modules/shared/hocs/ErrorBoundary/ErrorBoundary';
import NotFound from 'modules/shared/hocs/NotFound/NotFound';
import MainLayout from 'modules/shared/layouts/MainLayout/MainLayout';
import { UserAddForm, UserEditForm, UserList } from 'modules/users';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import AppLayout from './AppLayout';

const router = createBrowserRouter([
    {
        path: '/',
        element: <AppLayout />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/login',
                element: <Login />,
            },
            {
                path: '/',
                element: <AuthGuard page={<MainLayout />} />,
                children: [
                    {
                        index: true,
                        element: <Navigate to={'/dashboard'} replace />,
                    },
                    {
                        path: 'dashboard',
                        element: <h2 className="">This is a dashboard</h2>,
                    },
                    {
                        path: 'users',
                        element: <UserList />,
                    },
                    {
                        path: 'users/add',
                        element: <UserAddForm />,
                    },
                    {
                        path: 'users/:userId/edit',
                        element: <UserEditForm />,
                    },
                    {
                        path: 'listings',
                        element: <ListingList />,
                    },
                    {
                        path: 'listings/add',
                        element: <ListingAddForm />,
                    },
                    {
                        path: 'listings/:listingId/edit-listing',
                        element: <ListingEditForm />,
                    },
                ],
            },
            {
                path: '/404',
                element: <NotFound />,
            },
        ],
    },
]);

const AppRouter = () => {
    return <RouterProvider router={router} />;
};

export default AppRouter;
